import { navigate } from "gatsby-link";
import React from "react";
import "./style.scss";
import Timer from "../../../../common/timer";
import { common } from "../../../../../util/constants";

const CartItem = ({
  product,
  order,
  updateCartStatus,
  status,
  displayToastr,
  index,
  setPendingProductsArray,
}) => {
  const modifyProductHandler = () => {
    let uId = localStorage.getItem("userId");

    if (!uId || uId === null) {
      displayToastr("Must Login/Signup!!");
    } else if (
      order.others &&
      (order.others === "SWATCH" || (order.others && order.others === "BOOK"))
    ) {
      displayToastr("Can't Modify");
    } else {
      navigate(`/product/modify/${order._id}`);
    }
  };

  const updateCartStatus2 = () => {
    let cartItems = JSON.parse(localStorage.getItem("cartItems"));
    let items =
      cartItems && cartItems != null && cartItems.length > 0
        ? [...cartItems]
        : [];

    items.splice(index, 1);
    localStorage.setItem("cartItems", JSON.stringify(items));
    setPendingProductsArray(items);
  };

  let orderDate = new Date(order.updatedAt)
  let currentDate = new Date()
  const orderDatePlusExtraTime = new Date(order.updatedAt);
  orderDatePlusExtraTime.setMinutes(
    orderDate.getMinutes() + common.CART_EMPTY_TIME
  );

  let stockInvalid = (order.stock && !order.sample && (currentDate > orderDatePlusExtraTime)) ? true : false;

  function subtractMinutes(date) {
    date.setMinutes(date.getMinutes() - common.CART_EMPTY_TIME);
  
    return date;
  }

  return (
    <>
      {product.internalId && (
        <div
          className={`row cart-item ${
            ((status === "pending" && product.status === "deleted") ||
            stockInvalid) &&
            "border-red"
          }`}
        >
          {product.status === "deleted" && (
            <div className="out-of-stock-conatiner">
              <div className="out-of-stock">Discontinued!</div>
            </div>
          )}
          {(stockInvalid) && (
            <div className="out-of-stock-conatiner">
              <div className="out-of-stock">
                Timed out!
              </div>
            </div>
          )}
          {(!order.others || (order.others && order.others == "")) && (
            <div className="col-5 col-xl-4">
              <img
                src={
                  product.images &&
                  product.images.length > 0 &&
                  product.images[0].image
                }
              />
            </div>
          )}
          {order.others && order.others === "SWATCH" && (
            <div className="col-5 col-xl-4 disFlex">
              <img src="/images/common/Swatch Image.jpg" />
            </div>
          )}
          {order.others && order.others === "BOOK" && (
            <div className="col-5 col-xl-4 disFlex">
              <img src="/images/common/pantones/1.webp" />
            </div>
          )}
          {order.stock && !order.sample && (new Date(order.updatedAt) > subtractMinutes(new Date())) && (
            <div className="timer-container">
              <Timer 
              startTime={order.updatedAt} 
              keyName={order._id}
              />
            </div>
          )}
          <div className="col-7 col-xl-8">
            <div className="cart-info">
              <span className="bold">Style# </span>: 
              {product.internalId.split('-')[0]}
              {product.internalId.includes('-') ? ` (${product.internalId.split('-')[1]})` : ''} {" "}
              {/* {product.internalId}{" "} */}
              {product.others}
            </div>
            {/* <div className="cart-info">
              <span className="bold">Type</span> : {product.internalId}
            </div> */}
            <div className="cart-info">
              <span className="bold">Unit </span> : {order.totalQuantity}
            </div>
           {order.sample && order.ordercolors && order.ordercolors[0] && order.ordercolors[0].size && <div className="cart-info">
              <span className="bold">Size </span> : {order.ordercolors[0].size}
            </div>}
            <div className="cart-info">
              <span className="bold">Cost </span> : $
              {order.totalPrice.toFixed(2)}
            </div>
          </div>
          <div className="cart-item-buttons">
            <button
              onClick={modifyProductHandler}
              disabled={
                (order.others &&
                  (order.others === "SWATCH" ||
                    (order.others && order.others === "BOOK"))) ||
                product.status === "deleted"||
                // order.stock||
                stockInvalid
              }
            >
              Modify
            </button>
            {status === "pending" && (
              <button
                onClick={updateCartStatus.bind(
                  null,
                  order._id,
                  "savedForLater"
                )}
                disabled={stockInvalid}
              >
                Save for later
              </button>
            )}
            {status === "savedForLater" && (
              <button
                onClick={updateCartStatus.bind(null, order._id, "pending")}
                disabled={(product.status === "deleted") || stockInvalid}
              >
                Add to cart
              </button>
            )}
            {/* pending */}
            {localStorage.getItem("userId") &&
            localStorage.getItem("userId") !== null ? (
              <button
                onClick={updateCartStatus.bind(null, order._id, "deleted")}
              >
                Delete
              </button>
            ) : (
              <button onClick={updateCartStatus2.bind(null)}>Delete</button>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CartItem;
