import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { common } from "../../../util/constants";
import "./style.scss";

const minuteSeconds = 60;
const hourSeconds = 3600;
const initialTime = common.CART_EMPTY_TIME * 60;
const colorChange = [
  initialTime,
  initialTime / 2,
  initialTime / 2.95,
  initialTime / 3,
  // initialTime * 0.25,
  0,
];
console.log(initialTime, colorChange);

const renderTime = (dimension, time) => {
  return (
    <div className="time-wrapper">
      <div className="time">{time}</div>
      <div>{dimension}</div>
    </div>
  );
};

const getTimeSeconds = (time) => +(minuteSeconds - time).toFixed(2, 2) | 0;
const getTimeMinutes = (time) => ((time % hourSeconds) / minuteSeconds) | 0;
// const getTimeHours = (time) => ((time % daySeconds) / hourSeconds) | 0;
// const getTimeDays = (time) => (time / daySeconds) | 0;

export default function Timer({ startTime, keyName }) {
  const [timerProps, setTimerProps] = useState({
    isPlaying: true,
    size: 80,
    strokeWidth: 4,
  });

  useEffect(() => {
    if (window.innerWidth < 786) setTimerProps({ ...timerProps, size: 60 });
    const handleWindowSizeChange = () => {
      if (window.innerWidth < 786) {
        setTimerProps({ ...timerProps, size: 60 });
      } else {
        setTimerProps({ ...timerProps, size: 80 });
      }
    };

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const stratTime = Date.now(startTime) / 1000; // use UNIX timestamp in seconds

  var d1 = new Date(startTime);
  var d2 = new Date(startTime);
  let currentDate = new Date();
  let remainingTime = 0;
  d2.setMinutes(d1.getMinutes() + common.CART_EMPTY_TIME);

  // if (d2 > currentDate) {
  const endTime = d2 / 1000; // use UNIX timestamp in seconds
  remainingTime = endTime - stratTime;
  // }

  return (
    <div className="App">
      {/* {remainingTime} */}
      {remainingTime > 0 && (
        <CountdownCircleTimer
          key={keyName}
          {...timerProps}
          duration={initialTime}
          colors={["#00AB8C", "#00AB8C", "#00AB8C", "#FF6363", "#FF6363"]}
          colorsTime={colorChange}
          initialRemainingTime={remainingTime % hourSeconds}
          onComplete={() => {
            // your api call
            return { shouldRepeat: true, delay: 0 };
          }}
        >
          {({ elapsedTime, color }) => (
            <span style={{ color }}>
              {renderTime(
                `${getTimeMinutes(initialTime - elapsedTime)}:${
                  (initialTime - elapsedTime) % minuteSeconds < 10 ? "0" : ""
                }${getTimeSeconds(
                  minuteSeconds - ((initialTime - elapsedTime) % minuteSeconds)
                )}`
              )}
            </span>
          )}
        </CountdownCircleTimer>
      )}
    </div>
  );
}
