import React, { useState, useEffect, Fragment } from "react";
import { connect } from "react-redux";

import CartItem from "./cart-item";
import "./style.scss";
import {
  getCartDataHandler,
  updateCartStatusAction,
} from "../../../../actions/product/action";
import Toaster from "../../../common/Toaster";
import Lottie from "react-lottie-player";
import lottieJson from "../../../../../static/images/common/cart-icon.json";
import Loader from "../../../common/Loader";
import { navigate } from "gatsby-link";
import Timer from "../../../common/timer";
import { common } from "../../../../util/constants";

const Cart = ({ getCartDataHandler, updateCartStatusAction, state }) => {
  const [pendingProducts, setPendingProducts] = useState("");
  const [savedForLaterProducts, setSavedForLaterProducts] = useState("");
  const [pendingProductsArray, setPendingProductsArray] = useState([]);
  const [savedForLaterProductsArray, setSavedForLaterProductsArray] = useState(
    []
  );
  const [loading, setLoading] = useState(false);
  const [totalPrice, setTotalPrice] = useState(0);

  const [refresh, doRefresh] = useState(0);
  const [toastrMsg, setToastrMsg] = useState("");
  const [isMobile, setMobile] = useState(false);
  const [checkoutMsg, displayCheckoutMsg] = useState("Checkout");
  

  useEffect(() => {
    if (window.innerWidth < 576) setMobile(true);
    const handleWindowSizeChange = () => {
      window.innerWidth < 576 ? setMobile(true) : setMobile(false);
    };
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    setPendingProducts(
      <Fragment>
        {pendingProductsArray.map((product,index) => (
          <CartItem
            key={product._id? product._id : index}
            product={product.product ? product.product : {}}
            order={product}
            status="pending"
            updateCartStatus={updateCartStatushandler}
            displayToastr={displayToastr}
            setPendingProductsArray={setPendingProductsArray}
          />
        ))}
      </Fragment>
    );

    let total =
      pendingProductsArray.reduce((acc, curr) => (acc += curr.totalPrice), 0) ||
      0;
    setTotalPrice(total.toFixed(2));
  }, [pendingProductsArray]);

  useEffect(() => {
    setSavedForLaterProducts(
      <Fragment>
        {savedForLaterProductsArray.map((product,index) => (
          <CartItem
            key={product._id? product._id : index}
            product={product.product}
            order={product}
            status="savedForLater"
            updateCartStatus={updateCartStatushandler}
            displayToastr={displayToastr}
            index={index}
            setPendingProductsArray={setPendingProductsArray}
          />
        ))}
      </Fragment>
    );
  }, [savedForLaterProductsArray]);

  const updateCartStatushandler = async (orderId, status) => {
    let uId = localStorage.getItem("userId");
    
    if (!uId || uId === null) {
      displayToastr("Must Login/Signup!!!!")
    }else{
    let updatedStatus = await updateCartStatusAction({
      userId: localStorage.getItem("userId"),
      orderId,
      status,
    });
    if (
      updatedStatus.error ||
      updatedStatus.responseData.message ||
      !updatedStatus.responseData.data.add.updated
    ) {
      if(updatedStatus.responseData.message==='Sample already exists!'){
        setToastrMsg("Sample already exists!");
      }else{
        setToastrMsg("Unable to Change Status");
      }
      doRefresh((prev) => prev + 1);
    } else {
      getCartData();
    }
  }
  };

  useEffect(async () => {
    // if (localStorage.getItem("userId")) {
      getCartData();
    // }

    let uId = localStorage.getItem("userId");
    
    if (!uId || uId === null) {
      displayCheckoutMsg("Check out")
    }
    

  }, []);

  const getCartData = async () => {
    setLoading(true);
    let uId = localStorage.getItem("userId");
    
    if (!uId || uId === null) {
      
      let cartItems = localStorage.getItem("cartItems");
      if(JSON.parse(cartItems) != null && JSON.parse(cartItems).length>0){
        setPendingProductsArray(JSON.parse(cartItems));  
      }
      setLoading(false);
    } else {
      let cartdata = await getCartDataHandler(uId);
      
      if (
        cartdata &&
        !cartdata.error &&
        cartdata.responseData &&
        cartdata.responseData.data &&
        cartdata.responseData.data.cart
      ) {
        setSavedForLaterProductsArray([
          ...cartdata.responseData.data.cart.savedForLaterCartProducts,
        ]);
        setPendingProductsArray([
          ...cartdata.responseData.data.cart.pendingCartProducts,
        ]);
        setLoading(false);
      } else {
        setToastrMsg(cartdata.errorData);
        doRefresh((prev) => prev + 1);
        setLoading(false);
      }
    }
  };

  const navigateToCheckout = () => {
    let uId = localStorage.getItem("userId");
    
    if (!uId || uId === null) {
      navigate("/onboarding/step-one");    
    } else {

      for(let pendingProduct of pendingProductsArray){
        if(pendingProduct.product.status==='deleted'){
          setToastrMsg("Please remove the discontinued items!");
          doRefresh((prev) => prev + 1);
          return;
        }
        
        
        else if(pendingProduct.stock){
          let orderDate = new Date(pendingProduct.createdAt)
          let currentDate = new Date()
          const orderDatePlusExtraTime = new Date(pendingProduct.createdAt);
          orderDatePlusExtraTime.setMinutes(
            orderDate.getMinutes() + common.CART_EMPTY_TIME
          );
          if(currentDate > orderDatePlusExtraTime){
            setToastrMsg("Please refresh page!");
            doRefresh((prev) => prev + 1);
            return;
          }
        }
        // console.log(pendingProduct)
      }

    navigate("/products/cart/checkout-details");
  }
  };

  const displayToastr = (message) =>{
    setToastrMsg(message);
    doRefresh((prev) => prev + 1);
  }

  const goBack = () => {
    const localstorageProduct = JSON.parse(
      localStorage.getItem("currentProduct")
    );
    // navigate("/product/" + localstorageProduct);
    // navigate("/products/collections")
    navigate(-1)
    
  };

  return (
    <Fragment>
      <Toaster refresh={refresh} type="error" message={toastrMsg} />
      <Loader isLoading={loading} />
      <div className="cart">
        <div className="card">
          <div className="cart-header">
            <span className="header-left">
              <img
                className="cart-back"
                src="/images/common/left-arrow-blue2.svg"
                onClick={goBack}
              />
            </span>
            Order Detail
          </div>
          <div className="cart-body">
            <div className="cart-body-left col-12 col-md-6">
              {/* <img src="/images/common/cart-image.svg" /> */}
              {!isMobile && (
                <Lottie
                  loop
                  animationData={lottieJson}
                  play
                  style={{ width: 400, height: 400, margin: "auto" }}
                />
              )}
              {isMobile && (
                <Lottie
                  loop
                  animationData={lottieJson}
                  play
                  style={{ width: 200, height: 200, margin: "auto" }}
                />
              )}
            </div>
            <div className="cart-body-right col-12 col-md-6">
              <div className="cart-blue cb-color">
                {pendingProducts}
                <div>
                  <div className="summary-heading">Summary</div>
                  {/* <div className="summary-item row">
                    <div className="col-8 col-md-9">Subtotal</div>
                    <div className="col-4 col-md-3">
                      <span className="bold">${totalPrice ? totalPrice.split('.')[0] : 0}</span>
                      <span className="after-decimal">.{totalPrice ? totalPrice.split('.')[1]: 0}</span>
                    </div>
                  </div>
                  <div className="summary-item row">
                    <div className="col-8 col-md-9">Tax</div>
                    <div className="col-4 col-md-3">
                      <span className="bold">$0</span>
                      <span className="after-decimal">.00</span>
                    </div>
                  </div>

                  <div className="summary-item row">
                    <div className="col-8 col-md-9">shipping</div>
                    <div className="col-4 col-md-3">
                      <span className="bold">$0</span>
                      <span className="after-decimal">.00</span>
                    </div>
                  </div> 
                  <div className="summary-line"></div>*/}
                  <div className="summary-item big row">
                    <div className="col-8 col-md-9 bold">Subtotal</div>
                    <div className="col-4 col-md-3">
                      <span className="bold">
                        ${totalPrice ? totalPrice.split(".")[0] : 0}
                      </span>

                      <span className="after-decimal">
                        .{totalPrice ? totalPrice.split(".")[1] : 0}
                      </span>
                    </div>
                  </div>
                  <button
                    className="cart-checkout"
                    onClick={navigateToCheckout}
                    disabled={pendingProductsArray.length<=0}
                  >
                  {checkoutMsg}
                  </button>
                  <div className="summary-line"></div>
                </div>
              </div>
              {savedForLaterProductsArray.length > 0 && (
                <div className="section-saved-for-later cart-yellow">
                  <div className="heading">Saved For Later</div>
                  {savedForLaterProducts}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state) => ({
  state: state,
});

export default connect(mapStateToProps, {
  getCartDataHandler,
  updateCartStatusAction,
})(Cart);
