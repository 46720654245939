import React from 'react'
import Cart from '../../components/cards/CollectionsCard/cart'
// import { LiveChatWidget } from '@livechat/widget-react'

const cart = () => {
  return (
    <>
    <Cart />
    {/* <LiveChatWidget
      license="14411064"
      visibility="minimized"
    /> */}
    </>
    
    
  )
}

export default cart